import { useAxios } from 'hooks/useAxios';
import { AxiosErrorHandler } from 'sb/shared/Helpers';
import { BASE_URL } from 'sb/shared/constants';

export const useSupplierDynamicsApi = () => {
  const { axios } = useAxios(BASE_URL);

  /**
   * Fetch Supplier Deal Dynamics
   */
  const fetchSupplierDealDynamics = async (id: string | number) => {
    try {
      const res = await axios.get(`/secure/supplier/${id}/dealDynamics`);
      if (res.data?.status === 'success') {
        return res.data?.data;
      }
      return Promise.reject(Error(res.data?.message));
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Insert Supplier Deal Dynamics records
   *
   * @param supplierDealDynamics
   * @param supplierDealDynamicsNotes
   */
  const insertSupplierDealDynamics = async (supplierDealDynamics: object, supplierDealDynamicsNotes: object) => {
    try {
      const res = await axios.post(`/secure/supplier/dynamics`, { supplierDealDynamics, supplierDealDynamicsNotes });
      if (res.data?.status === 'success') {
        return res.data?.data;
      }
      return Promise.reject(Error(res.data?.message));
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Fetch Deal Dynamics Supplier info for Administration page
   *
   * @param id
   */
  const fetchDealDynamicsSupplierAdmin = async (id: string | number) => {
    try {
      const res = await axios.get(`/secure/supplier/${id}/deal_dynamics/admin`);
      if (res.data?.status === 'success') {
        return res.data?.data;
      }
      return Promise.reject(Error(res.data?.message));
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Update supplier_deal_dynamic note
   *
   * @param id
   * @param body
   */
  const updateSupplierDealDynamic = async (id: number, body: { is_accepted?: boolean; value?: number }) => {
    try {
      const res = await axios.put(`/secure/supplier_deal_dynamic/${id}`, body);
      if (res.data?.status === 'success') {
        return res.data?.data;
      }
      return Promise.reject(Error(res.data?.message));
    } catch (e) {
      AxiosErrorHandler(e);
      throw new Error();
    }
  };

  /**
   * Delete Deal Dynamics Supplier record
   *
   * @param id
   */
  const deleteSupplierDealDynamic = async (id: number) => {
    try {
      const res = await axios.delete(`/secure/supplier_deal_dynamic/${id}`);
      if (res.data?.status === 'success') {
        return res.data?.data;
      }
      return Promise.reject(Error(res.data?.message));
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Update supplier_deal_dynamic_note record
   *
   * @param id
   * @param body
   */
  const updateSupplierDealDynamicNote = async (id: number, body: { is_released?: boolean; note?: string }) => {
    try {
      const res = await axios.put(`/secure/supplier_deal_dynamic_note/${id}`, body);
      if (res.data?.status === 'success') {
        return res.data?.data;
      }
      return Promise.reject(Error(res.data?.message));
    } catch (e) {
      AxiosErrorHandler(e);
      throw new Error();
    }
  };

  /**
   * Delete Deal Dynamics Supplier Note record
   *
   * @param id
   */
  const deleteSupplierDealDynamicNote = async (id: number) => {
    try {
      const res = await axios.delete(`/secure/supplier_deal_dynamic_note/${id}`);
      if (res.data?.status === 'success') {
        return res.data?.data;
      }
      return Promise.reject(Error(res.data?.message));
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  return {
    fetchSupplierDealDynamics,
    insertSupplierDealDynamics,
    fetchDealDynamicsSupplierAdmin,
    updateSupplierDealDynamic,
    deleteSupplierDealDynamic,
    updateSupplierDealDynamicNote,
    deleteSupplierDealDynamicNote
  };
};
