import { CustomFieldElement, Deal, Contacts } from 'models/Deal';
import {
  Avatar,
  Button,
  Card,
  Col,
  Comment,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  notification,
  Popover,
  Row,
  Select,
  Skeleton,
  Typography
} from 'antd';
import moment from 'moment-timezone';
import { EditOutlined, PhoneOutlined, DeleteFilled, ArrowRightOutlined, MailOutlined } from '@ant-design/icons';
import React, { useEffect, useMemo, useState } from 'react';
import { Contract, EditDealRequest } from 'types/Contract';
import { renderCustomFields } from 'shared/CustomFieldsUtil';
import { profileSwitchState } from 'recoil/atoms';
import { useContractApi, useEditDeal } from 'api/contract';
import { useCustomFieldApi } from 'api/custom_field';
import { round } from 'lodash-es';
import { CustomField } from 'models/CustomField';
import { useNavigate } from 'react-router-dom';
import IconSubmit from '../../../../assets/icon-submit.svg';
import { ANONYMOUS_CLIENT_EMAIL, ANONYMOUS_CLIENT_USER } from '../../../../shared/constants';
import { Contact, useSalesforceApi } from 'api/salesforce';
import { useRecoilValue } from 'recoil';
import type { InputStatus } from 'antd/lib/_util/statusUtils';

interface Props {
  deal?: Deal;
  closeModal?: () => void;
  refetchDeal?: () => void;
  onDeleteDeal?: (dealId: number) => void;
}

const defaultFieldsKeys = [
  'contract_start_date',
  'contract_renewal_date',
  'client_contact_id',
  'supplier',
  'subject',
  'term',
  'annual_value',
  'total_value',
  'reseller'
];

/**
 * Represents a modal component used to display and edit deal summary information.
 *
 * @component
 * @param {Object} props - The props object containing deal data and event handlers.
 * @param {Deal} props.deal - The deal object containing deal information.
 * @param {Function} props.closeModal - The function to close the modal.
 * @param {Function} props.refetchDeal - The function to refetch deal data.
 * @returns {React.Component} The DealModalSummary component.
 */
const CalendarDealSummary: React.FC<Props> = ({ deal, closeModal, refetchDeal }: Props): React.ReactElement => {
  // LOCAL STATE
  const [term, setTerm] = useState(1);
  const [isUserDealEditEnabled, setIsUserDealEditEnabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingCustomFields, setLoadingCustomFields] = useState(false);
  const [customFields, setCustomFields]: any = useState([]);
  const [selectedPrimaryClientId, setSelectedPrimaryClientId] = useState<string | null>(null);
  const [selectedSecondaryClientIds, setSelectedSecondaryClientIds] = useState<string[]>([]);
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [primaryContactStatus, setPrimaryContactStatus] = useState<InputStatus>('');

  const profileSwitch = useRecoilValue(profileSwitchState);

  // API
  const { deleteDeal } = useContractApi();
  const { fetchCustomFields, fetchPublishStatus } = useCustomFieldApi();
  const { getSfAccountContacts } = useSalesforceApi();
  const navigate = useNavigate();
  const isDealLoading = !deal;

  const [sfDealEditForm] = Form.useForm();
  const [userDealEditForm] = Form.useForm();
  const tcv = Form.useWatch('total_value', userDealEditForm);
  const annualValue = useMemo(() => {
    return deal?.total_value ? round(Number(deal.total_value) / (term || 1), 2) : 0;
  }, [deal, term]);
  const { mutate: editDeal, isLoading } = useEditDeal({
    onSuccess: () => {
      refetchDeal && refetchDeal();
      notification.success({
        message: 'Deal updated successfully'
      });
      setIsUserDealEditEnabled(false);
    },
    onError: () => {
      notification.error({
        message: 'Deal update failed'
      });
      userDealEditForm.resetFields(['subject', 'total_value', 'contract_start_date', 'contract_renewal_date']);
      setIsUserDealEditEnabled(false);
    }
  });

  useEffect(() => {
    (async () => {
      try {
        const clientContacts: Contact[] | undefined = await getSfAccountContacts(profileSwitch?.id);
        if (Array.isArray(clientContacts)) setContacts(clientContacts);
      } catch (error) {
        console.error('Error fetching client contacts:', error);
      }
    })();
  }, [profileSwitch?.id]);

  useEffect(() => {
    if (deal) {
      setSelectedPrimaryClientId(deal?.primaryContact?.user_id || null);
      setSelectedSecondaryClientIds(deal?.secondaryContacts?.map((contact) => contact.user_id) || []);
    }
  }, [deal]);

  useEffect(() => {
    (async () => {
      setLoadingCustomFields(true);
      try {
        const publishStatus = await fetchPublishStatus();
        if (publishStatus?.isPublished) {
          const customFieldsRes = profileSwitch?.id
            ? await fetchCustomFields(true, profileSwitch?.id)
            : await fetchCustomFields(true);
          setCustomFields(customFieldsRes?.customFields);
        }
      } catch (err) {
        console.error('Error occurred while fetching published custom fields', err);
      } finally {
        setLoadingCustomFields(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (tcv) {
      userDealEditForm.setFieldsValue({
        annual_value: annualValue
      });
    }
  }, [userDealEditForm, tcv, annualValue]);

  useEffect(() => {
    !selectedPrimaryClientId ? setPrimaryContactStatus('error') : setPrimaryContactStatus('');
  }, [selectedPrimaryClientId]);

  useEffect(() => {
    if (!deal) return;
    if (deal.contract_renewal_date && deal.contract_start_date) {
      setTerm(moment(deal?.contract_renewal_date).diff(deal?.contract_start_date, 'years', true));
    } else if (deal.projected_close_date && deal.projected_start_date) {
      setTerm(moment(deal?.projected_close_date).diff(deal?.projected_start_date, 'years', true));
    } else {
      setTerm(1);
    }
  }, [deal]);

  const handlePrimaryClientChange = (value: string | null) => {
    setSelectedPrimaryClientId(value);
  };

  const handleSecondaryClientChange = (value: string[]) => {
    setSelectedSecondaryClientIds(value);
  };

  const filteredPrimaryClientOptions = useMemo(() => {
    return contacts?.filter((contact) => !selectedSecondaryClientIds?.includes(contact?.user_id));
  }, [contacts, selectedSecondaryClientIds]);

  const filteredSecondaryClientOptions = useMemo(() => {
    return contacts.filter((contact) => contact.user_id !== selectedPrimaryClientId);
  }, [contacts, selectedPrimaryClientId]);

  const handleUserDealSubmit = (values: Partial<Deal> & { subject: string; annual_value: number }) => {
    if (primaryContactStatus == 'error') return;
    if (!selectedPrimaryClientId) {
      setPrimaryContactStatus('error');
      return;
    }
    const { total_value } = values;
    const customFields = {};
    if (deal) {
      try {
        const defaultFields = {
          total_value,
          name: deal.name,
          subject: values.subject || '',
          contract_start_date: values.contract_start_date,
          contract_renewal_date: values.contract_renewal_date,
          reseller_id: deal?.reseller?.id,
          primaryContactUserId: selectedPrimaryClientId,
          secondaryContactUserIds: selectedSecondaryClientIds
        } as Partial<Contract>;

        Object.entries(values).forEach(([key, value]) => {
          if (!defaultFieldsKeys.includes(key)) {
            Object.assign(customFields, { [key]: value });
          }
        });
        const customPayload: EditDealRequest = {
          defaultFields,
          customFields
        };
        editDeal({ ...customPayload, dealId: deal.id });
      } finally {
        setLoading(false);
      }
    }
  };

  /**
   * Memoized variable that calculates initial values for the user deal form.
   * The initial values are derived from the `deal` and `term` variables.
   * If the `deal` is present, and it's not an sfDeal, the initial values are calculated based on the deal's properties.
   * Otherwise, an empty object is returned.
   *
   * @param {Object} deal - The deal object
   * @param {Object} term - The term object
   */
  const userDealFormInitialValues = useMemo(() => {
    if (deal && !deal.sfDeal) {
      const baseFields = {
        subject: deal?.subject || deal?.name,
        total_value: deal?.total_value ? round(Number(deal.total_value), 2) : 0,
        annual_value: deal?.total_value ? round(Number(deal.total_value), 2) / (term || 1) : 0,
        contract_start_date: deal?.contract_start_date ? moment.utc(deal?.contract_start_date) : undefined,
        contract_renewal_date: deal?.contract_renewal_date ? moment.utc(deal?.contract_renewal_date) : undefined
      };

      deal.client.custom_field.forEach((field: CustomFieldElement) => {
        Object.assign(baseFields, {
          [field.key]:
            field.data_type === 'date' && field.custom_field_value[0]?.val
              ? moment(field.custom_field_value[0]?.val)
              : field.data_type === 'boolean'
              ? field.custom_field_value[0]?.val === 'true'
              : field.custom_field_value[0]?.val
        });
      });
      return baseFields;
    }
    return {};
  }, [deal, term]);

  const renderEditButton = () => (
    <Col>
      <Button
        type="link"
        size="small"
        icon={<EditOutlined />}
        onClick={() => {
          setIsUserDealEditEnabled(true);
        }}
        disabled={isUserDealEditEnabled}
      >
        Edit Metadata
      </Button>
    </Col>
  );

  const handleRemoveDealFromCalendar = async (deal: Deal | undefined) => {
    if (deal?.id) {
      try {
        const res = await deleteDeal(deal?.id);
        if (res) {
          message.success(`Deal has been deleted!`, 1.5);
          setLoading(false);
          setTimeout(() => {
            navigate('/deal_calendar');
          }, 1500);
        }
      } catch (error) {
        console.error('Error deleting deal:', error);
        message.error('There was an error deleting the deal.');
      }
    }
  };

  const resetForm = () => {
    const customFieldsKey = deal?.client?.custom_field.map((field: CustomFieldElement) => field?.key);
    setIsUserDealEditEnabled(false);
    setPrimaryContactStatus('');
    setSelectedPrimaryClientId(deal?.primaryContact?.user_id || null);
    setSelectedSecondaryClientIds(deal?.secondaryContacts?.map((contact) => contact?.user_id) || []);
    const userDealEditFormFields = ['subject', 'total_value', 'contract_start_date', 'contract_renewal_date'];
    userDealEditForm.resetFields(
      customFieldsKey && customFieldsKey?.length > 0
        ? userDealEditFormFields.concat(customFieldsKey)
        : userDealEditFormFields
    );
  };

  const renderUserDealSalesContact = () => (
    <Comment
      avatar={
        <Avatar style={{ background: '#152d51' }}>
          {deal?.sfAccount?.Id
            ? deal?.sfAccount.Owner.FirstName.charAt(0) + deal?.sfAccount.Owner.LastName.charAt(0)
            : 'NPI'}
        </Avatar>
      }
      author={deal?.sfAccount?.Owner?.Name}
      content={
        <>
          <PhoneOutlined /> {deal?.sfAccount ? deal.sfAccount.Owner.MobilePhone : '--'}
        </>
      }
    />
  );

  const renderSubmitPurchaseLink = () => (
    <>
      <div className="flex mb-2">
        <img src={IconSubmit} alt="Icon" />
        <h3>
          Request a Benchmark Report to check if you&apos;re getting the best deal, spot issues, and get suggestions to
          improve the terms.
        </h3>
      </div>
      <Button
        type="primary"
        size="large"
        icon={<ArrowRightOutlined />}
        onClick={() => navigate(`/submit_purchase/${deal?.id}`)}
      >
        Proceed
      </Button>
    </>
  );

  const publishedCustomFields = () => {
    const enabledCustomFields = customFields.filter((item: CustomField) => item.isEnabled);
    if (enabledCustomFields.length > 0) {
      return renderCustomFields(enabledCustomFields);
    } else {
      return <span>No Custom Fields available for Publish</span>;
    }
  };

  const renderPrimaryClientContact = () => (
    <>
      <div style={{ fontSize: 15, fontWeight: 700 }}>Primary Contact</div>

      {isUserDealEditEnabled ? (
        <>
          <Select
            className="w-full"
            placeholder="Select Primary Contact"
            size="large"
            allowClear
            showArrow
            virtual={false}
            defaultValue={selectedPrimaryClientId || null}
            onChange={handlePrimaryClientChange}
            showSearch={true}
            fieldNames={{ label: 'Name', value: 'user_id' }}
            options={filteredPrimaryClientOptions}
            optionLabelProp="Name"
            optionFilterProp="Name"
            status={primaryContactStatus}
          />
          {primaryContactStatus == 'error' && <span className="text-red-500">Primary contact is required</span>}
        </>
      ) : (
        <Comment
          avatar={
            <Avatar style={{ background: '#30aa9e' }}>
              {deal?.primaryContact
                ? deal.primaryContact.FirstName.charAt(0).toUpperCase() +
                  deal.primaryContact.LastName.charAt(0).toUpperCase()
                : 'NPI'}
            </Avatar>
          }
          author={
            deal?.primaryContact ? (profileSwitch?.anonymous ? ANONYMOUS_CLIENT_USER : deal.primaryContact.Name) : '--'
          }
          content={
            <>
              <MailOutlined />{' '}
              {deal?.primaryContact
                ? profileSwitch?.anonymous
                  ? ANONYMOUS_CLIENT_EMAIL
                  : deal.primaryContact.Email
                : '--'}
            </>
          }
        />
      )}
    </>
  );

  const renderSecondaryClientContact = () => {
    return (
      <>
        <div className="text-base font-bold">Secondary Contacts</div>

        {isUserDealEditEnabled ? (
          <Select
            className="w-full"
            mode="multiple"
            placeholder="Select Secondary Contacts"
            size="large"
            allowClear
            showArrow
            virtual={false}
            value={selectedSecondaryClientIds}
            onChange={handleSecondaryClientChange}
            showSearch={true}
            fieldNames={{ label: 'Name', value: 'user_id' }}
            options={filteredSecondaryClientOptions}
            optionLabelProp="Name"
            optionFilterProp="Name"
          />
        ) : (
          <div className="max-h-[200px] overflow-y-auto">
            {deal?.secondaryContacts?.map((contact: Contacts) => (
              <Comment
                key={contact.user_id}
                avatar={
                  <Avatar style={{ background: '#30aa9e' }}>
                    {contact.FirstName.charAt(0).toUpperCase()}

                    {contact.LastName.charAt(0).toUpperCase()}
                  </Avatar>
                }
                author={profileSwitch && profileSwitch.anonymous ? ANONYMOUS_CLIENT_USER : contact.Name}
                content={
                  <>
                    <MailOutlined /> {profileSwitch && profileSwitch.anonymous ? ANONYMOUS_CLIENT_EMAIL : contact.Email}
                  </>
                }
              />
            ))}
          </div>
        )}
      </>
    );
  };

  const renderUserDealForm = () => (
    <Form
      className={isUserDealEditEnabled ? '' : 'view-mode'}
      form={userDealEditForm}
      labelCol={{ span: 8 }}
      labelAlign="left"
      wrapperCol={{ span: 16 }}
      colon={false}
      disabled={!isUserDealEditEnabled}
      initialValues={userDealFormInitialValues}
      onFinish={handleUserDealSubmit}
    >
      <Form.Item label="Supplier" name="supplier">
        <Typography.Text>{deal?.vendor.name}</Typography.Text>
      </Form.Item>
      {deal?.reseller?.name && (
        <Form.Item label="Reseller" name="reseller">
          <Typography.Text>{deal?.reseller?.name}</Typography.Text>
        </Form.Item>
      )}
      <Form.Item label="Subject" name="subject">
        <Input />
      </Form.Item>
      <Form.Item label="Total Contract Value" name="total_value">
        <InputNumber
          prefix="$"
          style={{ width: '200px' }}
          precision={2}
          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={(value) => (value ? value.replace(/\$\s?|(,*)/g, '') : 0)}
        />
      </Form.Item>
      <Form.Item label="Annual Value (calculated)" name="annual_value">
        <InputNumber
          prefix="$"
          style={{ width: '200px' }}
          disabled
          precision={2}
          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          // parser={(value) => (value ? value.replace(/\$\s?|(,*)/g, '') : 0)}
        />
      </Form.Item>
      <Form.Item label="Contract Start" name="contract_start_date" rules={[{ required: true, message: 'Contract start date is required' }]}>
        <DatePicker
          size="large"
          style={{ width: '200px' }}
          className="rounded-md"
          format={'MMM DD, YYYY'}
          disabledDate={(current) => {
            const endDate = userDealEditForm.getFieldValue('contract_renewal_date');
            return current && endDate && current.isAfter(endDate);
          }}
        />
      </Form.Item>
      <Form.Item label="Contract Renewal" name="contract_renewal_date" rules={[{ required: true, message: 'Contract renewal date is required' }]}>
        <DatePicker
          size="large"
          style={{ width: '200px' }}
          className="rounded-md"
          format={'MMM DD, YYYY'}
          disabledDate={(current) => {
            const endDate = userDealEditForm.getFieldValue('contract_start_date');
            return current && endDate && current.isBefore(endDate);
          }}
        />
      </Form.Item>
      {customFields.length > 0 && (
        <>
          <Divider className="uppercase" orientation="left" style={{ fontSize: 14 }}>
            Custom Fields
          </Divider>
          {publishedCustomFields()}
        </>
      )}
    </Form>
  );

  const DeleteConfirmationContent = (
    <div className="h-[180px] w-[200px]">
      <h3 className="font-bold text-base pt-1 pl-1 ">Remove Deal</h3>
      <hr className="border-t-1 border-dashed border-gray-400" />
      <p className="text-sm font-normal">
        Are you sure to delete this deal from the calendar?
        <br />
        <br /> All information will be lost.
      </p>
      <div className="flex justify-between">
        <Button
          type="primary"
          htmlType="submit"
          loading={isLoading}
          danger
          onClick={() => handleRemoveDealFromCalendar(deal)}
        >
          Remove
        </Button>
      </div>
    </div>
  );

  return (
    <div className="deal-modal-summary">
      <Card className="pt-4">
        <Skeleton loading={isDealLoading || loadingCustomFields}>
          <Row gutter={36}>
            <Col span={16}>
              <Row>
                <Col flex="auto">
                  <Typography.Title level={4}>Summary</Typography.Title>
                </Col>
              </Row>
              {deal ? renderUserDealForm() : null}
            </Col>
            <Col span={8}>
              <Card className={'contacts-card-body'} title="Sales Contact">
                {renderUserDealSalesContact()}
                <Divider />
                {renderPrimaryClientContact()}
                <Divider />
                {renderSecondaryClientContact()}
              </Card>
              <Card className={'bg-gray-100'} title="Request A Benchmark Report">
                {renderSubmitPurchaseLink()}
              </Card>
              {/*)}*/}
            </Col>
          </Row>
          <>
            <hr className="my-4" />
            <Row className={'align-items-center'}>
              <Col className={'mt-1'}>{renderEditButton()}</Col>
              <Col>
                <Popover content={DeleteConfirmationContent} trigger="click" overlayClassName="custom-deal-popover">
                  <Button type="link" className="text-red-500 font-bold hover:text-red-500 focus:text-red-500">
                    <DeleteFilled className="text-red-500" /> Remove from calendar
                  </Button>
                </Popover>
              </Col>
            </Row>
          </>
        </Skeleton>
      </Card>

      {isUserDealEditEnabled ? (
        <Row justify="end" className="footer-cta">
          <Col>
            <Button onClick={() => resetForm()}>Cancel</Button>
            <Button
              loading={loading || isLoading}
              type={'primary'}
              htmlType="submit"
              className="ml-4"
              onClick={() => (isUserDealEditEnabled ? userDealEditForm.submit() : sfDealEditForm.submit())}
            >
              Save
            </Button>
          </Col>
        </Row>
      ) : (
        closeModal && (
          <Row justify="end" className="footer-cta">
            <Col>
              {deal?.status?.toLowerCase() === 'calendar' ? (
                <Popover content={DeleteConfirmationContent} trigger="click" overlayClassName="custom-deal-popover">
                  <Button type="link" className="text-red-500 hover:text-red-500 focus:text-red-500">
                    <DeleteFilled className="text-red-500" /> Remove from calendar
                  </Button>
                </Popover>
              ) : null}
            </Col>
            <Col>
              <Button onClick={closeModal}>Close</Button>
            </Col>
          </Row>
        )
      )}
    </div>
  );
};

export default CalendarDealSummary;
