import { useAxios } from 'hooks/useAxios';
import { AxiosErrorHandler } from 'sb/shared/Helpers';
import { AuthUser, Client } from '../../models/User';
import { BASE_URL } from 'sb/shared/constants';

export const useUsersApi = () => {
  const { axios } = useAxios(BASE_URL);

  const searchAuth0Users = async (name: string) => {
    try {
      const res = await axios.get(`/secure/admin/users`, {
        params: { name }
      });
      if (res.data?.status === 'success') {
        return res.data?.data;
      }
      return Promise.reject(Error(res.data?.message));
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const getClientUsers = async (profile_id?: number): Promise<AuthUser[] | undefined> => {
    try {
      const res = await axios.get(`/v1/client/users`, {
        params: { profile_id }
      });
      if (res.data?.status === 'success') {
        return res.data?.data;
      }
      return Promise.reject(Error(res.data?.message));
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Asynchronously retrieves user column settings from the server.
   *
   * This function sends a GET request to the 'api/user/columnSettings' endpoint
   * using Axios to fetch the settings related to user columns. It expects a
   * successful response from the server, which should include a data object
   * containing the column settings. If the response indicates a failure, the
   * function will reject the promise with the error message provided by the server.
   *
   * In case of an error during the request, such as network issues or
   * server-side errors, the AxiosErrorHandler function is invoked to manage
   * the error.
   *
   * @returns {Promise<any>} A promise that resolves with the column settings data
   *                         when the request is successful, or is rejected with an
   *                         error message in case of failure.
   */
  const getUserColumnsSettings = async (): Promise<any> => {
    try {
      const res = await axios.get(`api/user/columnSettings`);
      if (res.data?.status === 'success') {
        return res.data?.data;
      }
      return Promise.reject(Error(res.data?.message));
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Asynchronously creates user column settings by sending a POST request
   * to the server with the specified data payload.
   *
   * @param {Object} data - An object containing the configuration data for user column settings.
   * @param {string} data.name - The name identifying the user or the setting to be created.
   * @param {string[]} data.columns - An array of strings representing the column settings for the user.
   * @returns {Promise<any>} A promise that resolves with the server's response data if the request
   * is successful, or rejects with an error if the request fails.
   *
   * Handles server responses and errors, using a custom error handler function for network-related issues.
   */
  const createUserColumnSettings = async (data: { name: string; columns: string[] }): Promise<any> => {
    try {
      const res = await axios.post(`api/user/columnSettings`, data);
      if (res.data?.status === 'success') {
        return res.data?.data;
      }
      return Promise.reject(Error(res.data?.message));
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Updates the column settings for a user.
   *
   * This asynchronous function sends a PATCH request to the server to update the column settings
   * of a user specified by the user ID. The column settings to be updated include a name and
   * an array of column identifiers. The server response is expected to confirm the success of the
   * operation or provide an error message.
   *
   * @param {number} id - The ID of the user whose column settings are to be updated.
   * @param {Object} data - The data containing the new column settings.
   * @param {string} data.name - The name associated with the column settings.
   * @param {string[]} data.columns - An array of column identifiers to be updated for the user.
   * @returns {Promise<any>} - A promise that resolves to the updated data if the operation is successful; otherwise, it is rejected with an error.
   * @throws Will invoke an error handler if the request fails.
   */
  const updateUserColumnSettings = async (id: number, data: { name: string; columns: string[] }): Promise<any> => {
    try {
      const res = await axios.patch(`api/user/columnSettings/${id}`, data);
      if (res.data?.status === 'success') {
        return res.data?.data;
      }
      return Promise.reject(Error(res.data?.message));
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  return {
    searchAuth0Users,
    getClientUsers,
    getUserColumnsSettings,
    createUserColumnSettings,
    updateUserColumnSettings
  };
};
