import { useAxios } from 'hooks/useAxios';
import { AxiosErrorHandler } from 'shared/Helpers';
import { CustomField, UpdateCustomFieldRequest } from '../models/CustomField';
import { AxiosResponse } from 'axios';
import { BASE_URL } from 'shared/constants';

export const useCustomFieldApi = () => {
  const { axios } = useAxios(BASE_URL);

  /**
   * Fetch Custom Fields for the Client
   *
   * @param {boolean} isEnabled Indicator whether custom fields should be enabled or not
   * @param profile_id
   * @return {Promise<any>} A promise that resolves with the custom fields data or rejects with an error message
   */
  const fetchCustomFields = async (isEnabled: boolean, profile_id?: number): Promise<any> => {
    try {
      const res: AxiosResponse<any, any> = await axios.get(`/api/custom_fields`, {
        params: {
          isEnabled: isEnabled ? 'true' : undefined,
          profile_id: profile_id
        }
      });
      if (res?.data?.status === 'success') {
        return res?.data?.data;
      }
      return Promise.reject(Error(res?.data?.message || 'Failed to fetch custom fields'));
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const insertCustomFields = async (customFields: CustomField[]) => {
    const payload: { customFields: CustomField[] } = { customFields };
    try {
      const res = await axios.post(`/api/custom_fields`, payload);
      if (res?.data?.status === 'success') {
        return res?.data?.data;
      }
      return Promise.reject(Error(res?.data?.message || 'Failed to insert custom fields'));
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const upsertCustomField = async (customFieldId: number, customField: UpdateCustomFieldRequest) => {
    try {
      const res = await axios.patch(`/api/custom_fields/field/${customFieldId}`, customField);
      if (res?.data?.status === 'success') {
        return res?.data?.data;
      }
      return Promise.reject(Error(res?.data?.message || 'Failed to update custom fields'));
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const deleteCustomField = async (customFieldId: number) => {
    try {
      const res = await axios.delete(`/api/custom_fields/field/${customFieldId}`);
      if (res?.data?.status === 'success') {
        return res?.data;
      }
      return Promise.reject(Error(res?.data?.message || 'Failed to delete custom fields'));
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const fetchPublishStatus = async () => {
    try {
      const res = await axios.get(`/api/custom_fields/published_status`);
      if (res?.data?.status === 'success') {
        return res?.data?.data;
      }
      return Promise.reject(Error(res?.data?.message || 'Failed to fetch publish status'));
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const publishCustomField = async (isPublished: boolean) => {
    try {
      const res = await axios.patch(`/api/custom_fields/published_status/toggle`, {
        isPublished
      });
      if (res?.data?.status === 'success') {
        return res?.data;
      }
      return Promise.reject(Error(res?.data?.message || 'Failed to publish custom field'));
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const updateDisplayOrder = async (payload: { id: number | undefined; orderBy: number }[]) => {
    try {
      const res = await axios.patch(`/api/custom_fields/display_order`, {
        orderByData: payload
      });
      if (res?.data?.status === 'success') {
        return res?.data;
      }
      return Promise.reject(Error(res?.data?.message || 'Failed to update display order'));
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  return {
    fetchCustomFields,
    insertCustomFields,
    upsertCustomField,
    deleteCustomField,
    fetchPublishStatus,
    publishCustomField,
    updateDisplayOrder
  };
};
